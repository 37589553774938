import { render, staticRenderFns } from "./index.vue?vue&type=template&id=67e08d22"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FinlandiaHeader: require('/buddy/finlandia/nuxt/client/components/Finlandia/Header.vue').default,FinlandiaUvod: require('/buddy/finlandia/nuxt/client/components/Finlandia/Uvod.vue').default,FinlandiaPopis: require('/buddy/finlandia/nuxt/client/components/Finlandia/Popis.vue').default,FinlandiaPravidla: require('/buddy/finlandia/nuxt/client/components/Finlandia/Pravidla.vue').default})
